import { Account } from "../../../pages";
import userRouteMap from "../../../routeControl/userRouteMap";

export default function route() {
  return [
    {
      path: userRouteMap.ACCOUNT.path,
      name: "Account",
      key: userRouteMap.ACCOUNT.path,
      private: true,
      belongsToSidebar: true,
      element: <Account />,
    },
  ];
}
