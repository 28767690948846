const config = {
  NODE_ENV: process.env.NODE_ENV,
  NAME_KEY: process.env.REACT_APP_NAME_KEY || "App",
  NAME_TITLE: process.env.REACT_APP_NAME_TITLE || "app",
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
  DEFAULT_LANGUAGE: process.env.REACT_APP_DEFAULT_LANGUAGE || "en",
  IMAGE_URL: process.env.REACT_APP_IMAGE_PATH,
  BASEURL: process.env.BASEURL || "http://3.109.160.75:8000/v1",
  APP_URL: process.env.APP_BASE_URL || "http://localhost:3000",
};

export default config;
